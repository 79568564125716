var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.classes,
      on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave }
    },
    [
      _c(
        "NuxtLink",
        {
          staticClass: "menu-list-element__link",
          attrs: {
            "aria-label": "Menu list element",
            to: { path: _vm.item.routing }
          }
        },
        [
          _c(_vm.item.icon, {
            tag: "Component",
            attrs: { "fill-color": _vm.iconFillColor }
          }),
          _vm._v(" "),
          _c("TranslatableTitleProvider", {
            attrs: { title: _vm.item.title },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var translatableTitle = ref.translatableTitle
                  return [
                    _c("span", {
                      staticClass: "link-text",
                      domProps: { textContent: _vm._s(translatableTitle) }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }