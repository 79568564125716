var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListHeader",
    { attrs: { title: _vm.title } },
    [
      _c("TextField", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSearchButtonClicked,
            expression: "isSearchButtonClicked"
          }
        ],
        staticClass: "search-text-field",
        attrs: {
          value: _vm.searchValue,
          size: _vm.smallSize,
          placeholder: "Search..."
        },
        on: { input: _vm.onSearch, focus: _vm.onSearchFocus },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c("IconSearch", {
                  attrs: { "fill-color": _vm.searchIconFillColor }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _vm.searchable
        ? _c("Fab", {
            staticClass: "search-button",
            attrs: { theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.onSearchButtonClick.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function(ref) {
                    var color = ref.color
                    return [
                      _vm.isSearchButtonClicked
                        ? _c("IconClose", { attrs: { "fill-color": color } })
                        : _c("IconSearch", { attrs: { "fill-color": color } })
                    ]
                  }
                }
              ],
              null,
              false,
              2040239512
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }