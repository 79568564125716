var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListElement",
    _vm._b({}, "ListElement", _vm.$attrs, false),
    [
      _c(
        "NuxtLink",
        { staticClass: "list-link-element", attrs: { to: _vm.$attrs.to } },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }