var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "span",
    { staticClass: "list-element-icon" },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }