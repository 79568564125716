var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-section" },
    [
      _vm.title
        ? _c("MenuListHeader", { attrs: { title: _vm.title } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "menu-section__list" },
        _vm._l(_vm.visibleMenu, function(item, index) {
          return _c("MenuListElement", { key: index, attrs: { item: item } })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }