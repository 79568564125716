var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.classes,
      attrs: { draggable: !_vm.isDragged && !_vm.disabled, title: _vm.hint },
      on: { dragstart: _vm.onDragStart, dragend: _vm.onDragEnd }
    },
    [
      !_vm.isDragged
        ? [
            _vm._t("default"),
            _vm._v(" "),
            _c("IconDragDrop", { staticClass: "list-draggable-element__icon" })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }