var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _c(
        "ListElement",
        {
          attrs: { size: _vm.largeSize },
          nativeOn: {
            click: function($event) {
              return _vm.expandGroup.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "ListElementDescription",
            [
              _c("ListElementTitle", {
                attrs: { title: _vm.title, hint: _vm.hint }
              }),
              _vm._v(" "),
              _c("ListElementHint", { attrs: { title: _vm.subtitle } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ListElementAction",
            [_c("IconArrowSingle", { attrs: { state: _vm.iconState } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPrefetchingData ? _c("Preloader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }