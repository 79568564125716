var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TranslatableTitleProvider", {
    attrs: { title: _vm.title },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var translatableTitle = ref.translatableTitle
          return [
            _c("h3", {
              staticClass: "menu-section__title",
              domProps: { textContent: _vm._s(translatableTitle) }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }