var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListHeader",
    { attrs: { title: _vm.title } },
    [
      !_vm.isSearchButtonClicked
        ? _vm._t("select", function() {
            return [
              _c("Select", {
                attrs: {
                  value: _vm.selectedOption,
                  size: _vm.smallSize,
                  options: _vm.options
                },
                on: { input: _vm.onLanguageSelect }
              })
            ]
          })
        : _c("TextField", {
            attrs: {
              value: _vm.searchValue,
              autofocus: "",
              size: _vm.smallSize,
              placeholder: "Search..."
            },
            on: { input: _vm.onDebounceSearch, focus: _vm.onSearchFocus },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c("IconSearch", {
                      attrs: { "fill-color": _vm.searchIconFillColor }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
      _vm._v(" "),
      _c("Fab", {
        staticClass: "search-button",
        attrs: { theme: _vm.secondaryTheme },
        nativeOn: {
          click: function($event) {
            return _vm.onSearchButtonClick.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var color = ref.color
              return [
                _vm.isSearchButtonClicked
                  ? _c("IconClose", { attrs: { "fill-color": color } })
                  : _c("IconSearch", { attrs: { "fill-color": color } })
              ]
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }